import { i18n } from '@whoop/i18n/lang/client';
import type { CartProduct, MembershipProduct } from 'ui';
import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import type {
  MembershipOptionCardVariant,
  MembershipOptionsVariants,
} from 'ui/types/MembershipCard';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import type { JoinFlowContent } from 'services/generated/growth-content-service';
import type { SiteWidePromoContent } from '../../types/siteWidePromoTypes';
import { formatDisplayPrice } from './formatHelpers';
import { type TagType } from 'ui/components/MembershipOptionCard/MembershipOptionCard';

export const findMembershipByTrialMonths = (
  memberships: MembershipProduct[],
  trialMonths: number,
): MembershipProduct | undefined => {
  return memberships.find(
    (membership) => membership.trial_months === trialMonths,
  );
};

export const findMembershipById = (
  memberships: MembershipProduct[],
  id: string,
): MembershipProduct | undefined => {
  return memberships.find((membership) => membership.id === id);
};

export const getMembershipOnGiftToggleChange = (
  membershipOptions: MembershipProduct[],
  cartMembership?: CartProduct,
): MembershipProduct | undefined => {
  const defaultMembership = findMembershipByTrialMonths(membershipOptions, 12);
  if (!cartMembership) return defaultMembership;
  const membership = cartMembership.item as MembershipProduct;
  const equivalentMembership = findMembershipById(
    membershipOptions,
    membership.id,
  );
  return equivalentMembership ?? defaultMembership;
};

export const getMonthlyMembershipSubtitle = (
  monthlyMembership: MembershipProduct,
  isMonthOffPromoCode: boolean,
  currency: Currency,
  language: Language,
  region: Region,
): string => {
  if (isMonthOffPromoCode) {
    return i18n.t('orderPage:monthMinimum', {
      commitmentMonths: monthlyMembership.commitment_months,
    });
  }
  return i18n.t('membershipPage:monthlyMembershipSubtitle', {
    commitmentMonths: monthlyMembership.commitment_months,
    total: formatDisplayPrice(
      monthlyMembership.display_price * monthlyMembership.commitment_months,
      { currency, language, region },
    ),
  });
};

export const getTagType = ({
  language,
  onSale,
  membershipOptionsVariant,
  promoInfo,
  promoContent,
}: {
  language: Language;
  onSale?: boolean;
  membershipOptionsVariant?: string;
  promoInfo?: CheckoutPromo;
  promoContent?: JoinFlowContent;
}): TagType => {
  if (
    onSale ||
    membershipOptionsVariant === 'new-design' ||
    promoInfo?.affiliate ||
    promoInfo?.referral ||
    promoContent?.partnership_name
  )
    return 'eyebrow';

  if (language !== 'en') return 'rectangle';

  return 'triangle';
};

export const getMembershipOptionsVariant = (
  membershipOptionsVariant?: MembershipOptionsVariants,
  onSale?: boolean,
  promoInfo?: CheckoutPromo,
  siteWidePromoContent?: SiteWidePromoContent,
): MembershipOptionCardVariant => {
  let variant: MembershipOptionCardVariant =
    onSale &&
    (siteWidePromoContent?.membershipTag ||
      siteWidePromoContent?.membershipSubtext)
      ? 'sale'
      : 'default';

  if (onSale && promoInfo?.promo_code.code === 'PREPAIDBUNDLE')
    variant = 'prepaid-bundle';

  if (membershipOptionsVariant === 'new-design' && variant === 'default')
    variant = 'new-default';

  return variant;
};
